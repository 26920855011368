import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import DefaultHelmet from "../seo/DefaultHelmet";
import Banner from "../components/NotFound/Banner";

const NotFoundPage = () => (
	<div className="page-404">
		<DefaultLayout>
			<DefaultHelmet title="404: Not found"/>
				<Banner
					title="404: not found"
					description="404: not found"
					extraLinkText="Back to Homepage"
					extraLinkUrl="/"
				/>
		</DefaultLayout>
	</div>
);

export default NotFoundPage;
